import React from 'react';
import styled, { css } from 'styled-components';
import { TailSpin } from 'components/tail-spin';

const SLoading = styled.div<{ relativeToParent: boolean }>`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    ${({ relativeToParent, theme }) =>
        relativeToParent &&
        css`
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${theme.colors.black_overlay};
            z-index: 1;
        `};
`;

const SSpinner = styled(TailSpin)`
    width: 50px;
    height: 50px;
`;

const SFullScreen = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.black_overlay};
    z-index: ${({ theme }) => theme.z_index.loading};
`;

type Props = {
    className?: string;
    fullScreen?: boolean;
    relativeToParent?: boolean;
};

export const LoadingStatus = ({
    className,
    fullScreen = false,
    relativeToParent = false,
}: Props) => {
    if (fullScreen) {
        return (
            <SFullScreen>
                <SSpinner />
            </SFullScreen>
        );
    }
    return (
        <SLoading className={className} relativeToParent={relativeToParent}>
            <SSpinner />
        </SLoading>
    );
};
