import { createClient, fetchExchange } from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { authExchangeOptions } from 'api/graphql/config/authExchange';
import { API_URL } from 'constants/api';

// TODO: research about cacheExchange
export const client = createClient({
    url: API_URL,
    exchanges: [authExchange(authExchangeOptions), fetchExchange],
});
