import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as TuroLogo } from 'assets/turo-logo.svg';
import { NavListItem } from 'components/sidebar/components/NavListItem';
import { navItems } from './navItems';
import { useUser } from 'store/user/hooks/useUser';
import { RoutePath } from 'router/routes/routePath';

const SSidebarWrapper = styled.nav`
    flex-basis: 264px;
    flex-shrink: 0;
    background: ${({ theme }) => theme.colors.revolver};
`;

const STopSectionLink = styled(Link)`
    height: ${({ theme }) => theme.height.navbar};
    border-bottom: solid 1px ${({ theme }) => theme.colors.gray};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 24px;
`;

const STuroLogo = styled(TuroLogo)`
    width: 80px;
    height: 21px;
`;

const SNav = styled.nav`
    text-align: left;
`;

const SNavDescription = styled.p`
    margin: 0;
    padding: 27px 24px 8px;
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.semi_bold};
    letter-spacing: 2.2px;
    color: ${({ theme }) => theme.colors.gray};
    text-transform: uppercase;
`;

const SNavList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const Sidebar = () => {
    const { t } = useTranslation();
    const { pathname: currentPath } = useLocation();
    const {
        state: {
            user: { isAdmin },
        },
    } = useUser();

    return (
        <SSidebarWrapper>
            <STopSectionLink to={RoutePath.Structures} aria-label={t('navbar.structures')}>
                <STuroLogo />
            </STopSectionLink>
            <SNav>
                <SNavDescription>{t('sidebar.description')}</SNavDescription>
                <SNavList>
                    {isAdmin ? (
                        navItems.map(({ label, path, pathRoot }) => (
                            <NavListItem
                                key={path}
                                active={currentPath === path || currentPath.includes(pathRoot)}
                                label={t(label)}
                                path={path}
                            />
                        ))
                    ) : (
                        <NavListItem
                            active={
                                currentPath === navItems[0].path ||
                                currentPath.includes(navItems[0].pathRoot)
                            }
                            label={t(navItems[0].label)}
                            path={navItems[0].path}
                        />
                    )}
                </SNavList>
            </SNav>
        </SSidebarWrapper>
    );
};
