import React, { ReactNode, useReducer } from 'react';
import { UserContext } from './context';
import { initialData, reducer } from './reducer';

type Props = {
    children: ReactNode;
};

export const UserContextProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialData);

    return (
        <UserContext.Provider
            value={{
                state,
                dispatch,
            }}>
            {children}
        </UserContext.Provider>
    );
};
