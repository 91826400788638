import { useGetUser } from 'api/graphql/hooks/user/useGetUser';
import { useUser } from 'store/user/hooks/useUser';
import { clientRoutes } from 'router/routes/clientRoutes';
import { adminRoutes } from 'router/routes/adminRoutes';

export const useValidatedRoutes = () => {
    const {
        state: {
            user: { isAdmin, id },
        },
    } = useUser();
    const { data } = useGetUser();

    const routes = isAdmin || data?.authUser.isAdmin ? adminRoutes : clientRoutes;
    const isAuthenticated = id || data?.authUser;

    return {
        routes,
        isAuthenticated,
    };
};
