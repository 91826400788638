export enum RootRoutePath {
    Structure = 'structure',
    Organization = 'organization',
    User = 'user',
}

export enum RoutePath {
    Index = '/',
    Login = '/login',
    Onboarding = '/onboarding/:token',
    Settings = '/settings',
    Profile = '/profile',
    ForgotPassword = '/forgot-password',
    ResetPassword = '/reset-password/:token',
    Users = '/users',
    Organizations = '/organizations',
    OrganizationDetails = '/organizations/:organizationId',
    Structures = '/structures',
    StructuresAdd = '/structures/add',
    StructuresPreview = '/structures/:structureId',
    StructuresEdit = '/structures/:structureId/edit',
    StructuresManage = '/structures/:structureId/:polygonPathId/manage',
    StructuresFloorFinishingScheme = '/structures/:structureId/finishing-scheme',
    StructuresCoordinationMode = '/structures/:structureId/coordination-mode',
    StructuresDirectory = '/structures/:structureId/directories/:directoryId',
}
