import React, { lazy } from 'react';
import { clientRoutes } from 'router/routes/clientRoutes';
import { RoutePath } from 'router/routes/routePath';

const StructuresAddPage = lazy(() => import('pages/structures-add'));
const StructuresEditPage = lazy(() => import('pages/structures-edit'));
const UsersPage = lazy(() => import('pages/users'));
const OrganizationsPage = lazy(() => import('pages/organizations'));
const OrganizationDetailsPage = lazy(() => import('pages/organization-details'));
const StructureManagePage = lazy(() => import('pages/structure-manage'));
const SettingsPage = lazy(() => import('pages/settings'));

export const adminRoutes = [
    ...clientRoutes.filter(({ noAdminAccess }) => !noAdminAccess),
    {
        path: RoutePath.StructuresAdd,
        component: <StructuresAddPage />,
    },
    {
        path: RoutePath.StructuresEdit,
        component: <StructuresEditPage />,
    },
    {
        path: RoutePath.Users,
        component: <UsersPage />,
    },
    {
        path: RoutePath.Organizations,
        component: <OrganizationsPage />,
    },
    {
        path: RoutePath.OrganizationDetails,
        component: <OrganizationDetailsPage />,
    },
    {
        path: RoutePath.StructuresAdd,
        component: <StructuresAddPage />,
    },
    {
        path: RoutePath.StructuresManage,
        component: <StructureManagePage />,
    },
    {
        path: RoutePath.StructuresEdit,
        component: <StructuresEditPage />,
    },
    {
        path: RoutePath.Settings,
        component: <SettingsPage />,
    },
];
