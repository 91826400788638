import { RefObject, useEffect } from 'react';

const EVENTS = ['mousedown', 'touchstart'];

export const useClickOutside = (ref: RefObject<Node>, handler: (x: Event) => void) => {
    useEffect(() => {
        const listener = (event: Event) => {
            if (!ref || !ref.current || ref.current.contains(event.target as Node)) {
                return;
            }

            handler(event);
        };

        EVENTS.forEach((event) => document.addEventListener(event, listener));

        return () => {
            EVENTS.forEach((event) => document.removeEventListener(event, listener));
        };
    }, [ref, handler]);
};
