import { generatePath, useLocation } from 'react-router-dom';
import { RoutePath } from 'router/routes/routePath';
import { QueryType } from 'api/graphql/hooks/structures/useGetAncestors';
import { structureStart } from 'components/breadcrumbs/constants/staticCrumbs';

export const singleStructureCrumb = ({ name, id }: { name: string; id: string }) => ({
    name,
    path: generatePath(RoutePath.StructuresPreview, { structureId: id }),
});

export const generateStructureCrumb = (data: QueryType | undefined) => {
    return [
        ...structureStart,
        ...(data?.structure.ancestors.map(({ name, id }) => singleStructureCrumb({ name, id })) ||
            []),
        {
            name: data?.structure.name as string,
            path: generatePath(RoutePath.StructuresPreview, {
                structureId: data?.structure.id,
            }),
        },
    ];
};

export const useOrganizationDetailsCrumb = () => {
    const { state } = useLocation();

    if (state?.id) {
        return {
            name: state.name,
            path: generatePath(RoutePath.OrganizationDetails, {
                organizationId: state.id as string,
            }),
        };
    }
};
