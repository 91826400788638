import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppLayout } from 'layouts/app-layout';
import { Router } from './router';

export const App = () => {
    return (
        <BrowserRouter>
            <AppLayout>
                <Router />
            </AppLayout>
        </BrowserRouter>
    );
};
