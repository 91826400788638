import { useQuery } from 'urql';
import { Query, QueryStructureArgs } from 'api-types';
import { GET_STRUCTURE_ANCESTORS } from 'api/graphql/queries/structure';

export type QueryType = Pick<Query, 'structure'>;

export const useGetAncestors = (variables: QueryStructureArgs) => {
    const [{ fetching, data, error }, reexecuteQuery] = useQuery<QueryType>({
        query: GET_STRUCTURE_ANCESTORS,
        variables,
    });

    return { fetching, data, error, reexecuteQuery };
};
