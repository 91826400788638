import { UserAction, UserActionType, UserState } from './types';

export const initialData: UserState = {
    user: {
        isAdmin: false,
        isOnboarded: false,
        id: '',
        organization: undefined,
        createdAt: '',
        email: '',
        fullName: '',
    },
};

export const reducer = (state: UserState, action: UserActionType) => {
    switch (action.type) {
        case UserAction.Update: {
            return {
                ...state,
                user: action.payload,
            };
        }
        case UserAction.Clear: {
            return initialData;
        }
        default: {
            return initialData;
        }
    }
};
