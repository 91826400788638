import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Navbar } from 'components/navbar';
import { Sidebar } from 'components/sidebar';

const SWrapper = styled.div`
    display: flex;
    height: 100vh;
`;

const SRightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.white};
`;

const SMain = styled.main<{ fullWidthContent?: boolean }>`
    flex: 1;
    padding: ${({ fullWidthContent }) => (fullWidthContent ? 0 : '36px 24px')};
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

interface Props {
    children: ReactNode;
    fullWidthContent?: boolean;
}

export const Layout = ({ children, fullWidthContent }: Props) => {
    return (
        <SWrapper>
            <Sidebar />
            <SRightWrapper>
                <Navbar />
                <SMain fullWidthContent={fullWidthContent}>{children}</SMain>
            </SRightWrapper>
        </SWrapper>
    );
};
