import React, { ReactNode, useEffect } from 'react';
import { useGetUser } from 'api/graphql/hooks/user/useGetUser';
import { useUser } from 'store/user/hooks/useUser';
import { User, UserAction } from 'store/user/types';
import { LoadingStatus } from 'components/loading-status';

interface Props {
    children: ReactNode;
}

export const CheckUser = ({ children }: Props) => {
    const { fetching, data } = useGetUser();
    const { dispatch } = useUser();

    useEffect(() => {
        if (data) {
            const user = data.authUser;
            dispatch({
                type: UserAction.Update,
                payload: user as User,
            });
        }
    }, [data, dispatch]);

    if (fetching) {
        return <LoadingStatus />;
    }
    return <>{children}</>;
};
