import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Breadcrumbs } from 'components/breadcrumbs';
// import { LanguagePicker } from './components/LanguagePicker';
import { useClickOutside } from 'hooks/useClickOutside';
import { useUser } from 'store/user/hooks/useUser';
import { Link } from 'react-router-dom';
import { RoutePath } from 'router/routes/routePath';
import { useTranslation } from 'react-i18next';

const SHeader = styled.header`
    width: 100%;
    height: ${({ theme }) => theme.height.navbar};
    background: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    letter-spacing: ${({ theme }) => theme.font.letter_spacing.none};
    border-bottom: solid 1px ${({ theme }) => theme.colors.border_gray};
    padding: 0 24px;
`;

const SList = styled.ul`
    list-style: none;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    right: 14px;
    top: 30px;
    padding: 8px;
    width: 176px;
    z-index: ${({ theme }) => theme.z_index.dropdown};
    box-shadow: ${({ theme }) => theme.shadow.settings};
    margin: 0;
    border-radius: 2px;
`;

const SListItem = styled.li`
    text-align: center;
    font-weight: ${({ theme }) => theme.font.weight.medium};
    font-size: ${({ theme }) => theme.font.size.small};

    &:hover {
        background-color: ${({ theme }) => theme.colors.highlight};
    }
`;

const SLink = styled(Link)`
    text-decoration: none;
    padding: 10px 0;
    display: inline-block;
    width: 100%;
    font-weight: ${({ theme }) => theme.font.weight.medium};
    font-size: ${({ theme }) => theme.font.size.medium};
    color: ${({ theme }) => theme.colors.revolver};
`;

const SButtonLogout = styled.button`
    width: 100%;
    cursor: pointer;
    padding: 10px 0;
    border: 0;
    background-color: transparent;
    font-weight: ${({ theme }) => theme.font.weight.medium};
    font-size: ${({ theme }) => theme.font.size.medium};
    color: ${({ theme }) => theme.colors.revolver};
`;

const SAccountWrapper = styled.div`
    padding: 16px 0 16px 24px;
    display: flex;
    position: relative;
    flex: 1;
    justify-content: flex-end;
`;

const SUserButton = styled.button`
    padding: 5px 16px;
    border: 0;
    appearance: none;
    background: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.font.weight.medium};
    font-size: ${({ theme }) => theme.font.size.small};
    cursor: pointer;
    max-height: 50px;
    overflow: auto;
`;

const SWrapper = styled.div`
    display: flex;
    position: relative;
`;

export const Navbar = () => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [isShowMenu, setIsShowMenu] = useState(false);

    const {
        state: {
            user: { fullName, isAdmin },
        },
    } = useUser();

    const onLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    useClickOutside(ref, () => {
        setIsShowMenu(false);
    });

    return (
        <SHeader>
            <Breadcrumbs />
            <SAccountWrapper ref={ref}>
                <SWrapper>
                    <SUserButton
                        onClick={() => {
                            setIsShowMenu(!isShowMenu);
                        }}>
                        {fullName}
                    </SUserButton>
                    {isShowMenu && (
                        <SList>
                            <SListItem>
                                <SLink
                                    to={isAdmin ? RoutePath.Settings : RoutePath.Profile}
                                    onClick={() => {
                                        setIsShowMenu(false);
                                    }}>
                                    {t(`navbar.${isAdmin ? 'settings' : 'profile'}`)}
                                </SLink>
                            </SListItem>
                            <SListItem>
                                <SButtonLogout onClick={onLogout}>
                                    {t('navbar.logout')}
                                </SButtonLogout>
                            </SListItem>
                        </SList>
                    )}
                </SWrapper>
                {/*<LanguagePicker />*/}
            </SAccountWrapper>
        </SHeader>
    );
};
