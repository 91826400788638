import { gql } from '@urql/core';

export const ALL_USERS_QUERY = gql`
    query GetAllUsers($usersPage: Int, $usersPerPage: Int, $usersFullName: String) {
        users(page: $usersPage, perPage: $usersPerPage, fullName: $usersFullName) {
            totalCount
            nodes {
                id
                createdAt
                email
                fullName
                isAdmin
                isOnboarded
                organization {
                    id
                    name
                }
            }
        }
    }
`;

export const USERS_LIST_QUERY = gql`
    query GetUsersList(
        $page: Int
        $perPage: Int
        $sort: UserSort
        $fullName: String
        $organizationId: ID
    ) {
        users(
            page: $page
            perPage: $perPage
            sort: $sort
            fullName: $fullName
            organizationId: $organizationId
        ) {
            totalCount
            nodes {
                id
                email
                fullName
                organization {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_USER_QUERY = gql`
    query GetUser {
        authUser {
            id
            createdAt
            email
            fullName
            isAdmin
            isOnboarded
            organization {
                id
                name
            }
        }
    }
`;

export const GET_USER_STRUCTURES_QUERY = gql`
    query GetUserStructures($id: ID!) {
        user(id: $id) {
            structures {
                nodes {
                    name
                }
                totalCount
            }
        }
    }
`;
