import { gql } from '@urql/core';

export const STRUCTURES_LIST_QUERY = gql`
    query GetStructures($page: Int, $perPage: Int, $sort: StructureSort) {
        structures(page: $page, perPage: $perPage, sort: $sort) {
            totalCount
            nodes {
                name
                createdAt
                id
                backgroundFileConvertUrl
                interactiveFileConvertUrl
            }
        }
    }
`;

export const GET_STRUCTURE = gql`
    query GetStructure($id: ID!) {
        structure(id: $id) {
            name
            backgroundFileConvertUrl
            interactiveFileConvertUrl
            backgroundFileConvertStatus
            interactiveFileConvertStatus
            finishingSchemeUrl
            floorNumber
            id
        }
    }
`;

export const GET_STRUCTURE_ORGANIZATIONS = gql`
    query GetStructureOrganizations($id: ID!, $organizationsPerPage: Int, $organizationsPage: Int) {
        structure(id: $id) {
            organizations(perPage: $organizationsPerPage, page: $organizationsPage) {
                totalCount
                nodes {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_STRUCTURE_USERS = gql`
    query GetStructureUsers($id: ID!, $userPerPage: Int, $usersPage: Int) {
        structure(id: $id) {
            users(perPage: $userPerPage, page: $usersPage) {
                totalCount
                nodes {
                    id
                    fullName
                    organization {
                        name
                    }
                }
            }
        }
    }
`;

export const STRUCTURE_PREVIEW_QUERY = gql`
    query GetStructurePreview($id: ID!, $perPage: Int, $page: Int, $sort: StructureSort) {
        structure(id: $id) {
            interactiveFileConvertUrl
            backgroundFileConvertUrl
            backgroundFileConvertStatus
            interactiveFileConvertStatus
            svgPolygonPath
            floorNumber
            id
            name
            type
            description
            ancestors {
                id
                description
            }
            children(perPage: $perPage, page: $page, sort: $sort) {
                totalCount
                nodes {
                    id
                    name
                    floorNumber
                    svgPolygonPath
                    description
                    backgroundFileConvertUrl
                    interactiveFileConvertUrl
                    finishingSchemeUrl
                    type
                    ancestors {
                        id
                        description
                    }
                }
            }
        }
    }
`;

export const GET_NESTED_STRUCTURE = gql`
    query GetNestedStructure($id: ID!, $sort: StructureSort, $perPage: Int, $page: Int) {
        structure(id: $id) {
            name
            id
            description
            interactiveFileConvertUrl
            backgroundFileConvertUrl
            backgroundFileConvertStatus
            interactiveFileConvertStatus
            svgPolygonPath
            finishingSchemeUrl
            type
            ancestors {
                name
                id
            }
            children(sort: $sort, perPage: $perPage, page: $page) {
                totalCount
                nodes {
                    name
                    floorNumber
                    svgPolygonPath
                    description
                    backgroundFileConvertUrl
                    interactiveFileConvertUrl
                    backgroundFileConvertStatus
                    interactiveFileConvertStatus
                    finishingSchemeUrl
                    id
                    type
                    ancestors {
                        name
                        id
                    }
                }
            }
        }
    }
`;

export const STRUCTURES_REVISION_LIST_QUERY = gql`
    query GetStructureRevisionList(
        $id: ID!
        $page: Int
        $perPage: Int
        $sort: StructureRevisionsSort
    ) {
        structure(id: $id) {
            revisions(page: $page, perPage: $perPage, sort: $sort) {
                totalCount
                nodes {
                    id
                    createdAt
                    name
                    files {
                        id
                        fileName
                        fileUrl
                    }
                    structure {
                        id
                        svgPolygonPath
                    }
                }
            }
        }
    }
`;

export const GET_STRUCTURE_ANCESTORS = gql`
    query GetStructureAncestors($id: ID!) {
        structure(id: $id) {
            id
            name
            ancestors {
                name
                id
            }
        }
    }
`;

export const GET_CURRENT_MANAGE_STRUCTURE = gql`
    query GetCurrentManageStructure($id: ID!, $perPage: Int, $page: Int) {
        structure(id: $id) {
            id
            ancestors {
                id
            }
            children(perPage: $perPage, page: $page) {
                totalCount
                nodes {
                    name
                    id
                    svgPolygonPath
                    type
                    description
                    ancestors {
                        id
                    }
                }
            }
        }
    }
`;
