import React, { ReactNode } from 'react';
import styled from 'styled-components';
import bgImage from 'assets/background-image.png';
import { ReactComponent as TuroLogo } from 'assets/turo-logo.svg';
import { useTranslation } from 'react-i18next';

const SLayout = styled.div`
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        overflow: hidden;
    }
`;

const Side = styled.div`
    overflow-y: auto;
    height: 100vh;
    padding: 35px 16px;
`;

const SLeftSide = styled(Side)`
    background: url(${bgImage}) no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: 150px 75px 35px;
    }
`;

const SRightSide = styled(Side)`
    background-color: ${({ theme }) => theme.colors.white};

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: 75px;
    }
`;

const SHeading = styled.h1`
    font-size: ${({ theme }) => theme.font.size.heading};
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.font.weight.semi_bold};
    max-width: 300px;
    margin-bottom: 16px;
    position: relative;

    ::after {
        content: '';
        width: 92px;
        height: 4px;
        background-color: ${({ theme }) => theme.colors.primary};
        position: absolute;
        top: -24px;
        left: 2px;
    }
`;

const SSubHeading = styled.h3`
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.font.weight.thin};
    letter-spacing: ${({ theme }) => theme.font.letter_spacing.spatially};
`;

interface Props {
    children?: ReactNode;
}

export const SplitLayout = ({ children }: Props) => {
    const { t } = useTranslation();

    return (
        <SLayout>
            <SLeftSide>
                <div>
                    <SHeading>{t('common.build-differently')}</SHeading>
                    <SSubHeading>{t('common.your-platform')}</SSubHeading>
                </div>
                <TuroLogo />
            </SLeftSide>
            <SRightSide>{children}</SRightSide>
        </SLayout>
    );
};
