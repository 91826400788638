import React from 'react';
import { useGetUser } from 'api/graphql/hooks/user/useGetUser';
import { Navigate, useLocation } from 'react-router';
import { useUser } from 'store/user/hooks/useUser';
import { RoutePath } from 'router/routes/routePath';

interface Props {
    children: JSX.Element;
}

export const PrivateRoute = ({ children }: Props) => {
    const { state } = useUser();
    const { data } = useGetUser();
    const location = useLocation();

    return state.user?.id || data?.authUser ? (
        children
    ) : (
        <Navigate to={RoutePath.Login} state={{ from: location }} />
    );
};
