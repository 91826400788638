import { StringMap } from 'i18next';
import { Dispatch } from 'react';

export enum Types {
    Update = 'UPDATE',
    Clear = 'CLEAR',
}

export type UserContextProps = {
    state: UserState;
    dispatch: Dispatch<UserActionType>;
};

export enum UserAction {
    Update = 'UPDATE',
    Clear = 'CLEAR',
}

export type UserState = {
    user: User;
};

export type UserActionType =
    | {
          type: UserAction.Update;
          payload: User;
      }
    | {
          type: UserAction.Clear;
      };

export type Organization = {
    id: string;
    createdAt: StringMap;
    name: string;
};

export type User = {
    id: string;
    fullName: string;
    email: string;
    createdAt: string;
    isAdmin: boolean;
    isOnboarded: boolean;
    organization?: Organization;
};
