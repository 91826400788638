import React, { lazy } from 'react';
import { RoutePath } from 'router/routes/routePath';

const IndexPage = lazy(() => import('pages/index'));
const LoginPage = lazy(() => import('pages/login'));
const ForgotPasswordPage = lazy(() => import('pages/forgot-password'));
const ResetPasswordPage = lazy(() => import('pages/reset-password'));
const OnboardingPage = lazy(() => import('pages/onboarding'));

export const publicRoutes = [
    {
        path: RoutePath.Index,
        component: <IndexPage />,
    },
    {
        path: RoutePath.Login,
        component: <LoginPage />,
    },
    {
        path: RoutePath.ForgotPassword,
        component: <ForgotPasswordPage />,
    },
    {
        path: RoutePath.ResetPassword,
        component: <ResetPasswordPage />,
    },
    {
        path: RoutePath.Onboarding,
        component: <OnboardingPage />,
    },
];
