import { useQuery } from 'urql';
import { GET_USER_QUERY } from 'api/graphql/queries/user';
import { Query } from 'api-types';

type QueryType = Pick<Query, 'authUser'>;

export const useGetUser = () => {
    const [{ fetching, data }] = useQuery<QueryType>({
        query: GET_USER_QUERY,
    });

    return { fetching, data };
};
