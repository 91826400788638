import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SButton = styled.button`
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin: 8px;
    &:hover {
        * {
            stroke: ${({ theme }) => theme.colors.primary};
        }
    }
    &:disabled {
        cursor: default;
        pointer-events: none;
    }
`;

interface Props {
    title: string;
    icon: ReactElement;
    onClick?: () => void;
    to?: string;
    className?: string;
    disabled?: boolean;
}

export const IconButton = ({ onClick, title, to, icon, className, disabled }: Props) => {
    const { t } = useTranslation();

    if (to) {
        return (
            <SButton as={Link} to={to} className={className} aria-label={t(title)}>
                {icon}
            </SButton>
        );
    }
    return (
        <SButton
            onClick={onClick}
            title={t(title)}
            className={className}
            aria-label={t(title)}
            disabled={disabled}>
            {icon}
        </SButton>
    );
};
