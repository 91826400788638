export const theme = {
    colors: {
        primary: '#eebf4f',
        revolver: '#363639',
        white: '#ffffff',
        black: '#000000',
        gray: '#58585b',
        primary_90: 'rgba(238, 191, 79, .9)',
        primary_40: 'rgba(238, 191, 79, .4)',
        primary_10: 'rgba(238, 191, 79, .1)',
        primary_05: 'rgba(238, 191, 79, .05)',
        white_03: 'rgba(255, 255, 255, 0.03)',
        rock_blue: '#afbdcf',
        light_gray: '#CBCBCB',
        light_gray_overlay: '#CBCBCB4D',
        border_gray: '#e6e6e6',
        shadow: '#4d3d091f',
        dark_gray: '#363639',
        error: 'red',
        white_overlay: 'rgba(255, 255, 255, 0.5)',
        highlight: '#F7F7F7',
        black_overlay: 'rgba(0, 0, 0, 0.5)',
    },
    font: {
        size: {
            heading: '64px',
            title: '36px',
            header: '24px',
            normal: '18px',
            base: '16px',
            medium: '14px',
            small: '12px',
            table_heading: '11px',
        },
        weight: {
            bold: 700,
            semi_bold: 600,
            normal: 400,
            medium: 500,
            light: 300,
            thin: 200,
        },
        letter_spacing: {
            spatially: '3.6px',
            none: '0',
        },
        line_height: {
            spatially: 1.5,
        },
    },
    breakpoints: {
        tablet: '768px',
        desktop: '1200px',
    },
    transition: {
        background: 'background-color 0.2s ease',
        all: 'all 0.2s linear',
        border: 'border-color 0.3s ease-in',
        stroke: 'stroke 0.2s ease',
    },
    shadow: {
        autofill: '0 0 0px 1000px #fff inset',
        dropdown: '0px 8px 20px #0000001F',
        table_row: '0px 4px 12px #00000014;',
        settings: '0px 0px 10px #0000001f;',
        ellipsis: '-1px 2px 14px 2px #0000001f;',
    },
    autofill: {
        off: 'autofill-off',
        on: 'autofill-on',
    },
    padding: {
        base: '16px',
        narrow: '10px',
    },
    z_index: {
        dropdown: 50,
        modal: 100,
        loading: 9999,
    },
    height: {
        navbar: '63px',
        table_row: '56px',
    },
};
