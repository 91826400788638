import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { App } from './App';
import { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import { GlobalStyle } from 'styles/globalStyle';
import { theme } from 'styles/theme';
import { Provider } from 'urql';
import { client } from 'api/graphql/config';
import { UserContextProvider } from 'store/user/provider';
import { CheckUser } from 'providers/CheckUser';
import Modal from 'react-modal';

declare const window: Window & typeof globalThis & { printApplicationVersion: () => void };

window.printApplicationVersion = () => {
    // eslint-disable-next-line no-console
    console.info({
        version: process.env.REACT_APP_VERSION,
        buildTimestamp: new Date(process.env.REACT_APP_BUILD_TIMESTAMP as string).toLocaleString(),
        rawBuildTimestamp: process.env.REACT_APP_BUILD_TIMESTAMP,
    });
};

Modal.setAppElement('#root');

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
            <Provider value={client}>
                <UserContextProvider>
                    <CheckUser>
                        <App />
                        <Toaster
                            toastOptions={{
                                style: {
                                    border: 'none',
                                    padding: '8px 20px',
                                    color: '#363639',
                                    fontSize: '16px',
                                    background: 'white',
                                    borderRadius: '5px',
                                    maxWidth: '500px',
                                    lineHeight: 1.4,
                                },
                            }}
                        />
                    </CheckUser>
                </UserContextProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
