import React, { lazy } from 'react';
import { RoutePath } from 'router/routes/routePath';

const StructuresPage = lazy(() => import('pages/structures'));
const StructurePreviewPage = lazy(() => import('pages/structure-preview'));
const FinishingSchemePage = lazy(() => import('pages/finishing-scheme'));
const StructureCoordinationModePage = lazy(() => import('pages/structure-coordination-mode'));
const StructureDirectoryPage = lazy(() => import('pages/structure-directory'));
const ProfilePage = lazy(() => import('pages/profile'));

export const clientRoutes = [
    {
        path: RoutePath.Structures,
        component: <StructuresPage />,
    },
    {
        path: RoutePath.StructuresPreview,
        component: <StructurePreviewPage />,
    },
    {
        path: RoutePath.StructuresFloorFinishingScheme,
        component: <FinishingSchemePage />,
    },
    {
        path: RoutePath.StructuresCoordinationMode,
        component: <StructureCoordinationModePage />,
    },
    {
        path: RoutePath.StructuresDirectory,
        component: <StructureDirectoryPage />,
    },
    {
        path: RoutePath.Profile,
        component: <ProfilePage />,
        noAdminAccess: true,
    },
];
