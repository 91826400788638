import { makeOperation, Operation } from '@urql/core';
import { LS_AUTH_TOKEN } from 'constants/auth';
import { CombinedError } from 'urql';
import { notifyError } from 'helpers/notifyError';

interface Tokens {
    token: string;
}

interface GetAuthPayload<T> {
    authState: T | null;
}

interface AddAuthToOperationPayload<T> {
    authState: T | null;
    operation: Operation;
}

interface WillAuthError<T> extends AddAuthToOperationPayload<T> {}

interface DidAuthError<T> {
    error: CombinedError;
    authState: T | null;
}

const getAuth = async ({ authState }: GetAuthPayload<Tokens | null>) => {
    if (!authState) {
        const token = localStorage.getItem(LS_AUTH_TOKEN);

        if (token) {
            return { token };
        }
        return null;
    }

    return { token: authState.token };
};

const addAuthToOperation = ({ authState, operation }: AddAuthToOperationPayload<Tokens | null>) => {
    if (!authState || !authState.token) {
        return operation;
    }

    const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.token}`,
            },
        },
    });
};

const didAuthError = ({ error }: DidAuthError<Tokens | null>) => {
    if (error.message === '[Network] Failed to fetch') {
        notifyError();
    }
    return error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
};

const willAuthError = ({ operation, authState }: WillAuthError<Tokens | null>) => {
    if (!authState) {
        // Detect our login mutation and let this operation through:
        return !(
            operation.kind === 'mutation' &&
            // Here we find any mutation definition with the "login" field
            operation.query.definitions.some((definition) => {
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.selectionSet.selections.some((node) => {
                        // The field name is just an example, since signup may also be an exception
                        return node.kind === 'Field' && node.name.value === 'login';
                    })
                );
            })
        );
    }

    return false;
};

export const authExchangeOptions = {
    getAuth,
    addAuthToOperation,
    didAuthError,
    willAuthError,
};
