import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'router/routes/routePath';
import { Layout } from 'layouts/layout';
import { SplitLayout } from 'layouts/split-layout';
import { findMatchingRoute } from 'utils/findMatchingRoute';

const ROUTES_WITH_SPLIT_LAYOUT = [
    RoutePath.ForgotPassword,
    RoutePath.Login,
    RoutePath.ResetPassword,
    RoutePath.Onboarding,
];

const ROUTES_WITHOUT_LAYOUT = [RoutePath.Index];

const ROUTES_FULL_WIDTH_CONTENT = [
    RoutePath.StructuresPreview,
    RoutePath.StructuresFloorFinishingScheme,
    RoutePath.StructuresCoordinationMode,
    RoutePath.StructuresDirectory,
];

interface Props {
    children: ReactNode;
}

export const AppLayout = ({ children }: Props) => {
    const { pathname } = useLocation();

    const useSplitLayout = !!findMatchingRoute(ROUTES_WITH_SPLIT_LAYOUT, pathname);
    const withoutLayout = !!findMatchingRoute(ROUTES_WITHOUT_LAYOUT, pathname);
    const fullWidthContent = !!findMatchingRoute(ROUTES_FULL_WIDTH_CONTENT, pathname);

    const ViewLayout = useSplitLayout ? SplitLayout : Layout;

    return withoutLayout ? (
        <>{children}</>
    ) : (
        <ViewLayout fullWidthContent={fullWidthContent}>{children}</ViewLayout>
    );
};
