import React from 'react';
import { Navigate, useLocation } from 'react-router';

interface Props {
    children: JSX.Element;
    redirectTo: string;
    isAuthenticated: boolean;
}

export const PublicRoute = ({ children, isAuthenticated, redirectTo }: Props) => {
    const location = useLocation();

    return isAuthenticated ? children : <Navigate to={redirectTo} state={{ from: location }} />;
};
