import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SParagraph = styled.p`
    margin: 0;
    font-size: ${({ theme }) => theme.font.size.normal};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    letter-spacing: 0;
    color: ${({ theme }) => theme.colors.white};
`;

const SLink = styled(Link)`
    padding: 15px 24px;
    text-decoration: none;
    display: inline-block;
    width: 100%;
`;

const SNavListItem = styled.li<{ active: boolean }>`
    width: 100%;
    position: relative;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.colors.white_03};
    }

    ::before {
        content: '';
        width: 4px;
        height: 100%;
        background: ${({ theme }) => theme.colors.primary};
        position: absolute;
        top: 0;
        left: 0;
        visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
    }

    :hover::before {
        visibility: visible;
    }

    & > a:active {
        text-decoration: none;
    }
`;

interface Props {
    label: string;
    path: string;
    active: boolean;
}

export const NavListItem = ({ label, path, active }: Props) => {
    return (
        <SNavListItem active={active}>
            <SLink to={path}>
                <SParagraph>{label}</SParagraph>
            </SLink>
        </SNavListItem>
    );
};
