import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useValidatedRoutes } from 'router/hooks/useValidatedRoutes';
import { RoutePath } from 'router/routes/routePath';
import { PublicRoute } from 'router/components/public-route';
import { PrivateRoute } from 'router/components/private-route';
import { RouteArgs } from 'router/types';
import { publicRoutes } from 'router/routes/publicRoutes';
import { LoadingStatus } from 'components/loading-status';

export const Router = () => {
    const { isAuthenticated, routes } = useValidatedRoutes();

    return (
        <Routes>
            {publicRoutes.map(({ path, component }) => {
                return (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <Suspense fallback={<LoadingStatus />}>
                                <PublicRoute
                                    redirectTo={RoutePath.Structures}
                                    isAuthenticated={!isAuthenticated}>
                                    {component}
                                </PublicRoute>
                            </Suspense>
                        }
                    />
                );
            })}
            {routes.map(({ path, component }: RouteArgs) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <Suspense fallback={<LoadingStatus />}>
                            <PrivateRoute>{component}</PrivateRoute>
                        </Suspense>
                    }
                />
            ))}
        </Routes>
    );
};
