import { matchPath } from 'react-router-dom';
import { RouteArgs } from 'router/types';

export const findMatchingRoute = (routes: (string | RouteArgs)[], pathname: string) => {
    const uuid = `([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})`;
    const regex = new RegExp(uuid);

    return routes.find((route) => {
        const routeReference = typeof route === 'string' ? { path: route } : route;

        const matchedPath = matchPath(routeReference.path, pathname);

        if (matchedPath && matchedPath.params) {
            if (matchedPath.params.structureId) {
                return regex.test(matchedPath.params.structureId);
            }
            if (matchedPath.params.organizationId) {
                return regex.test(matchedPath.params.organizationId);
            }
            if (matchedPath.params.directoryId) {
                return regex.test(matchedPath.params.directoryId);
            }
        }
        return !!matchedPath;
    });
};
